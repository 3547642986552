import React from "react";
import { graphql, navigate } from "gatsby";
import Layout from "../components/layout/Layout";
import PageLangLinkList from "../components/PageLangLinkList";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import grey_outline_circle from "../assets/images/grey_outline_circle.svg";
import grey_outline_rectangle from "../assets/images/grey_outline_rectangle.svg";
import grey_outline_square from "../assets/images/grey_outline_square.svg";
import grey_outline_cylinder from "../assets/images/grey_outline_cylinder.svg";
import grey_outline_transcript from "../assets/images/grey_outline_transcript.svg";
import { Button } from "../components/Button";
import { pushToGTM, getDefinedTermPopUpItem, postFBEvent } from "../helpers";
import { PageLinkList } from "../components/PageLinkList";
import ContentHTMLWithHoverPopUps from "../components/ContentHTMLWithHoverPopUps";
import HubspotForm from "../components/HubspotForm";

export default ({ data, pageContext, location }) => {
    const {
        title,
        ingress,
        createdAt,
        updatedAt,
        heroImage,
        introContent,
        contentImage,
        numbers,
        content,
        color,
        hubspotFormId,
        thankYouPageUrl,
        pageLinkLists,
        pageLinkListHeader
    } = data.contentfulServicesSubpage;
    const seoData = {
        updatedAt: updatedAt,
        createdAt: createdAt,
        image: heroImage.file.url,
    }
    const numbersIcons = [
        grey_outline_square,
        grey_outline_circle,
        grey_outline_cylinder,
        grey_outline_rectangle,
        grey_outline_transcript
    ];
    const { default_slug, available_locales } = pageContext;

    const handleSubmit = () => {
        //postToTGM("google_ads_service_whitepaper_form_submit", "Service Whitepaper Form Submit");
        pushToGTM({
            'event': 'frends_form_submit',
            'form_type': 'Service Whitepaper Form Submit',
            'resource_name': 'Service'
        })
        if (typeof window !== "undefined") {
            if (window.fbq != null) {
                window.fbq('track', 'CompleteRegistration')
            }
        }

        postFBEvent("CompleteRegistration", "Service Whitepaper Form")
        if (thankYouPageUrl !== null) {
            navigate(thankYouPageUrl);
        } else {
            navigate("/thanks-for-the-registration/");
        }
    }

    const definedTermsPopUpItems = data.allContentfulDefinedTerm.nodes.map(getDefinedTermPopUpItem);

    return (
        <Layout title={title} transparentNavigation={true} seoData={seoData} location={location}>
            <main id="content">
                <section className={`hero is-fullheight-with-navbar`}>
                    <GatsbyImage
                        image={getImage(heroImage)}
                        style={{ width: "100%", height: "100%", position: "absolute", top: "0", left: "0" }}
                        alt="" />
                    <div 
                        className="hero-body" 
                        style={{position: "relative" }} >
                        <div className="service-subpage--page-lang-links-content">
                            <div className="page-lang-links-wrapper">
                                <div className="container page-lang-links-container">
                                    <PageLangLinkList 
                                        defaultSlug={default_slug}
                                        availableLocales={available_locales} />
                                </div>
                            </div>
                        </div>
                        <div className="container has-text-centered">
                            <h1 className="font-variable size-100 line-height-100 is-white margin-bottom-0">
                                {title}
                            </h1>
                            <div className="columns">
                                <div className="column margin-top-40 is-half is-offset-one-quarter">
                                    <div className="padding-y-20 is-white font-regular size-30" dangerouslySetInnerHTML={{ __html: ingress.childMarkdownRemark.html }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="container content">
                        <div className="columns">
                            <div className="column is-four-fifths is-offset-1">
                                <ContentHTMLWithHoverPopUps
                                    contentHTML={introContent.childMarkdownRemark.html} 
                                    popUpItems={definedTermsPopUpItems}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                {numbers !== null && (
                    <section className="section">
                        <div className="container content">
                            <div className="columns flex-hcenter">
                                {numbers.map((number, i) => (
                                    <div className="column is-3" key={i}>
                                        <div style={{ position: "relative", textAlign: "center" }}>
                                            <img src={numbersIcons[i]} alt="" style={{ width: "100%" }} />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform: "translate(-50%, -50%)"
                                                }}
                                                className="content"
                                            >
                                                <h2 className={`title is-${color}`}>{number}</h2>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                )}
                {content !== null && (
                    <section className="section">
                        <div className="container content">
                            <div className="columns">
                                <div className="column is-four-fifths is-offset-1">
                                    <ContentHTMLWithHoverPopUps
                                        contentHTML={content.childMarkdownRemark.html} 
                                        popUpItems={definedTermsPopUpItems}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                {contentImage !== null && (
                    <section className="section">
                        <div className="container content">
                            <div className="columns">
                                <div className="column is-four-fifths is-offset-1">
                                    <GatsbyImage image={getImage(contentImage)} style={{ padding: "20px" }} alt="" />
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                {hubspotFormId !== null && (
                    <section className="section">
                        <div className="container content">
                            <div className="columns">
                                <div className="column is-four-fifths is-offset-1">
                                    <HubspotForm
                                        formId={hubspotFormId}
                                        loading={<div>Loading...</div>}
                                        onSubmit={handleSubmit}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                {pageLinkLists !== null && (
                    <section className={`section is-medium is-${color}-bg`}>
                        <div className="container content">
                            <div className="columns">
                                <div className="column is-three-fifths is-offset-one-fifth">
                                    <PageLinkList lists={pageLinkLists} header={pageLinkListHeader} />
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                <section className="section">
                    <div className="container content">
                        <div className="columns">
                            <div className="column is-four-fifths is-offset-1">
                                <Button
                                    color={color}
                                    text="contact us"
                                    type="outlined"
                                    to="/ask-us-anything"
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    );
}

export const query = graphql`
query ($id: String!, $contentful_content_vocabulary_item_slugs: [String]) {
    contentfulServicesSubpage(id: {eq: $id}) {
        pageLinkListHeader
        pageLinkLists {
            linkList {
              ... on ContentfulWebinarV2 {
                __typename
                id
                title
                webinarUrl
                webinarFreeText {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                webinarImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulServicesSubpage {
                __typename
                id
                title
                slug
                ingress {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                heroImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulLanding {
                __typename
                id
                title
                slug
                ingress {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                heroImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulCustomerType {
                __typename
                id
                name
                slug
                ingress {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                heroImage {
                  gatsbyImageData(width: 400, height: 400)
                }
              }
              ... on ContentfulCustomer {
                __typename
                id
                name
                description
                slug
                heroImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulCoSellPartner {
                __typename
                id
                name
                slug
                slogan
              }
              ... on ContentfulArticle {
                __typename
                id
                title
                articleUrl
                subtitle
                heroImage {
                  gatsbyImageData(width: 400, height: 400)
                }
              }
            }
            title
          }
        numbers
        title
        updatedAt
        createdAt
        introContent {
            childMarkdownRemark {
                html
            }
        }
        content {
            childMarkdownRemark {
                html
            }
        }
        ingress {
            childMarkdownRemark {
                html
            }
        }
        heroImage {
            gatsbyImageData(width: 1920)
            file {
                url
            }
        }
        contentImage {
            gatsbyImageData(width: 1920)
        }
        hubspotFormId
        thankYouPageUrl
        color
    }
    allContentfulDefinedTerm(
        filter: {slug: {in: $contentful_content_vocabulary_item_slugs}, node_locale: {eq: "en-US"}}
        ) {
        nodes {
            slug
            title
            description {
                childMarkdownRemark {
                    html
                    excerpt
                }
            }
        }
    }
}
`

